import React, {ChangeEvent, useState} from 'react';
import {Button, Col, Form, Modal, Row, Table} from "react-bootstrap";
import ReactPaginate from 'react-paginate';

interface OrderLineItemsProps {
  lineItems: any;
  onLineItemChecked: any;
  selectedLineItems: any;
  pageCount: number;
  itemsPerPage: number;
  handlePageClick: any;
  pageNumber: any;
}

const OrderLineItems: React.FC<OrderLineItemsProps> = ({lineItems, onLineItemChecked, selectedLineItems, pageCount, itemsPerPage, handlePageClick, pageNumber}) => {
  const [showModal, setShowModal] = useState(false);
  const [lineItemToDisplay, setLineItemToDisplay] = useState<any>(null);

  const handleClose = () => {
    setShowModal(false);
  }

  const handleShow = () => {
    setShowModal(true);
  }

  const handleLineItemChecked = (e: ChangeEvent<HTMLInputElement>, obj: any) => {
    onLineItemChecked(e, obj);
  }

  const handleSelectedItem = (obj: any) => {
    setLineItemToDisplay(obj);
    handleShow();
  }

  return (
    <>

      <p><strong>Order Line Items {(selectedLineItems === '') && <span>(Please select an item)</span>}</strong></p>
      <Table bordered striped hover size="sm">
        <thead>
        <tr>
          <th>{' '}</th>
          <th scope="col">Row #</th>
          <th scope="col">Item</th>
          <th scope="col">Description</th>
          <th scope="col">Tracking Number</th>
          <th scope="col">Quantity</th>
        </tr>
        </thead>
        <tbody>
        {lineItems.map((item: any) =>
          <tr key={item.rowNumber} className={item.selected ? "active" : ""} style={{ cursor: 'pointer' }}>
            <td>
              <input
                type="checkbox"
                checked={item.selected}
                id="rowcheck{item.rowNumber}"
                onChange={(e) => {handleLineItemChecked(e, item)}}
              />
            </td>
            <td onClick={() => handleSelectedItem(item)}>{item.rowNumber}</td>
            <td onClick={() => handleSelectedItem(item)}>{item.itemNumber}</td>
            <td onClick={() => handleSelectedItem(item)}>{item.itemDescription}</td>
            <td onClick={() => handleSelectedItem(item)}>{item.trackingNumber}</td>
            <td onClick={() => handleSelectedItem(item)}>{item.quantity}</td>
          </tr>
        )}
        </tbody>
      </Table>

      <nav className="d-flex justify-content-center">
        <ReactPaginate
          nextLabel="&raquo;"
          pageRangeDisplayed={itemsPerPage}
          pageCount={pageCount}
          previousLabel="&laquo;"
          renderOnZeroPageCount={null as any}
          containerClassName="pagination"
          breakClassName="page-item"
          breakLabel={<a className="page-link">...</a>}
          pageClassName="page-item"
          previousClassName="page-item"
          nextClassName="page-item"
          pageLinkClassName="page-link"
          previousLinkClassName="page-link"
          nextLinkClassName="page-link"
          activeClassName="active"
          onPageChange={handlePageClick}
          forcePage={pageNumber}
        />
      </nav>

      <Modal centered show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Item Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>

            <Row className="mb-1">
              <Col>
                <div className="form-floating">
                  <input
                    className="form-control"
                    id="itemNumber"
                    name="itemNumber"
                    value={lineItemToDisplay?.itemNumber}
                    autoComplete="off"
                    readOnly
                  />
                  <label className="form-label" htmlFor="itemNumber">Item</label>
                </div>
              </Col>
            </Row>

            <Row className="mb-1">
              <Col>
                <div className="form-floating">
                  <input
                    className="form-control"
                    id="itemDescription"
                    name="itemDescription"
                    value={lineItemToDisplay?.itemDescription}
                    autoComplete="off"
                    readOnly
                  />
                  <label className="form-label" htmlFor="itemDescription">Description</label>
                </div>
              </Col>
            </Row>

            <Row className="mb-1">
              <Col>
                <div className="form-floating">
                  <input
                    className="form-control"
                    id="trackingNumber"
                    name="trackingNumber"
                    value={lineItemToDisplay?.trackingNumber}
                    autoComplete="off"
                    readOnly
                  />
                  <label className="form-label" htmlFor="trackingNumber">Tracking Number</label>
                </div>
              </Col>
            </Row>

            <Row className="mb-1">
              <Col>
                <div className="form-floating">
                  <input
                    className="form-control"
                    id="shippingMethod"
                    name="shippingMethod"
                    value={lineItemToDisplay?.shippingMethod}
                    autoComplete="off"
                    readOnly
                  />
                  <label className="form-label" htmlFor="shippingMethod">Shipping Method</label>
                </div>
              </Col>
            </Row>

            <Row className="mb-1">
              <Col>
                <div className="form-floating">
                  <input
                    className="form-control"
                    id="quantity"
                    name="quantity"
                    value={lineItemToDisplay?.quantity}
                    autoComplete="off"
                    readOnly
                  />
                  <label className="form-label" htmlFor="quantity">Quantity</label>
                </div>
              </Col>
            </Row>

            <Row className="mb-1">
              <Col>
                <div className="form-floating">
                  <input
                    className="form-control"
                    id="quoteNbr"
                    name="quoteNbr"
                    value={lineItemToDisplay?.quoteNbr}
                    autoComplete="off"
                    readOnly
                  />
                  <label className="form-label" htmlFor="quoteNbr">Quote Number</label>
                </div>
              </Col>
            </Row>

            <Row className="mb-1">
              <Col>
                <div className="form-floating">
                  <input
                    className="form-control"
                    id="orderNbr"
                    name="orderNbr"
                    value={lineItemToDisplay?.orderNbr}
                    autoComplete="off"
                    readOnly
                  />
                  <label className="form-label" htmlFor="orderNbr">Order Number</label>
                </div>
              </Col>
            </Row>

            <Row className="mb-1">
              <Col>
                <div className="form-floating">
                  <input
                    className="form-control"
                    id="invoiceNumber"
                    name="invoiceNumber"
                    value={lineItemToDisplay?.invoiceNumber}
                    autoComplete="off"
                    readOnly
                  />
                  <label className="form-label" htmlFor="invoiceNumber">Invoice Number</label>
                </div>
              </Col>
            </Row>

          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button className="custom-button-lg" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

    </>
  );
}

export default OrderLineItems;
