import React from 'react';

export interface SpinnerProps {
  loading: boolean;
}

const Spinner: React.FC<SpinnerProps> = ({loading}) => {

  return (
    <>
      {loading &&
          <div className="d-flex justify-content-center">
              <div className="spinner-grow" role="status">
                  <span className="sr-only"></span>
              </div>
          </div>
      }
    </>
  );

}

export default Spinner;