import React from 'react';
import './App.css';
import CustomerInquiries from "./pages/CustomerInquiries";
import axios from "axios";
import {Container, Navbar} from "react-bootstrap";

axios.interceptors.request.use(config => {
  config.headers = {
    // @ts-ignore
    ApiKey: process.env.REACT_APP_API_KEY
  };
  return config;
});

function App() {
  return (
    <>

      <Navbar expand="lg" style={{ backgroundColor: 'rgb(0,46,93)' }}>
        <Container>
          <Navbar.Brand style={{ color: 'rgb(255,255,255)' }}>Rubber Fab Customer Inquiries</Navbar.Brand>
        </Container>
      </Navbar>

      <Container className="mt-3" style={{height: '100vh'}}>
        <CustomerInquiries />
      </Container>

    </>

  );
}

export default App;
