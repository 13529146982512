import React, {ChangeEvent, useEffect, useRef, useState} from 'react';
import axios from "axios";
import OrderLineItems from "../components/OrderLineItems";
import Spinner from "../components/Spinner";
import {useFormik} from "formik";
import * as Yup from "yup";
import ResultStatus from "../components/ResultStatus";
import {Col, Container, Form, Row} from "react-bootstrap";

const CustomerInquiries = () => {
  const ITEMS_PER_PAGE = parseInt(process.env.REACT_APP_PAGE_SIZE || '0');
  const SERVER_PAGE_SIZE = 1000000000;
  const SERVER_PAGE_number = 0;
  const REACT_APP_FILESIZE_LIMIT = parseInt(process.env.REACT_APP_FILESIZE_LIMIT || '0');
  const [countries, setCountries] = useState<any>([]);
  const [requestTypes, setRequestTypes] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState<string>('');
  const [combinedIssueTypes, setCombinedIssueTypes] = useState<any>([]);
  const [issueType, setIssueType] = useState<any>(null);
  const [issue, setIssue] = useState<any>(null);
  const [lineItems, setLineItems] = useState<any>([]);
  const [currentItems, setCurrentItems] = useState<any>([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [selectedLineItems, setSelectedLineItems] = useState<string>('');
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [preview, setPreview] = useState<any>();
  const [sortByColumnName, setSortByColumnName] = useState<string>('ITEMNMBR');
  const [sortByOrder, setSortByOrder] = useState<number>(1);
  const [formLabels, setFormLabels] = useState<any>({
    typeLabel: ''
  });
  const aRef = useRef(null);

  const formikSearch = useFormik({
    initialValues: {
      orderType: '',
      orderNumber: ''
    },
    onSubmit: values => {

      setLoading(true);

      let url = '';
      if (values.orderType === '1') {
        url = process.env.REACT_APP_API_URI + `/Order/GetOrderByCustPoNumber?custPoNumber=${values.orderNumber}&pageSize=${SERVER_PAGE_SIZE}&pageNumber=${SERVER_PAGE_number}&sortByColumnName=${sortByColumnName}&sortByOrder=${sortByOrder}`;
      } else if (values.orderType === '2') {
        url = process.env.REACT_APP_API_URI + `/Order/GetOrderBySopNumber?sopNumber=${values.orderNumber}&pageSize=${SERVER_PAGE_SIZE}&pageNumber=${SERVER_PAGE_number}&sortByColumnName=${sortByColumnName}&sortByOrder=${sortByOrder}`;
      }

      axios.get(url)
        .then(res => {

          if (res.data && res.data.recCount > 0) {

            let header: any = res.data.recs[0];
            formik.values.phone = header.phone;
            formik.values.customerName = header.customerName;
            formik.values.addressLine1 = header.address1;
            formik.values.addressLine2 = header.address2;
            formik.values.custPoNumber = header.custPoNumber;
            formik.values.city = header.city;
            formik.values.state = header.state;
            formik.values.postalCode = header.zip;
            formik.values.categoryCode = header.category;

            setLineItems(res.data.recs);
            setCurrentItems(res.data.recs.slice(0, ITEMS_PER_PAGE));
            setSelectedLineItems('');
            setPageNumber(0);
            setPageCount(Math.ceil(res.data.recCount / ITEMS_PER_PAGE));
            // setSelectedLineItem(null);
            setLoading(false);
          }
          else {
            clearSearchForm();
            setLineItems([]);
            setCurrentItems([]);
            setSelectedLineItems('');
            setPageNumber(0);
            setPageCount(0);
            setStatus('ERR#Could Not Find Any Matching Orders');
            setLoading(false);
            setTimeout(() => {
              setStatus('');
            }, parseInt(process.env.REACT_APP_WARNING_TIMEOUT || '0'));
          }
        })
        .catch(error => {
          console.log(error);
          setLineItems([]);
          setCurrentItems([]);
          setSelectedLineItems('');
          setPageCount(0);
          setLoading(false);
        });
    },
    validationSchema: Yup.object({
      orderType: Yup
        .string()
        .required("Required"),
      orderNumber: Yup
        .string()
        .required("Required")
    })
  });

  const formik = useFormik({
    initialValues: {
      customerName: '',
      custPoNumber: '',
      contactName: '',
      phone: '',
      email: '',
      contactPosition: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      postalCode: '',
      countryCode: 'US',
      categoryCode: '',
      requestTypeCode: '',
      issueJSON: '',
      description: '',
      otherIssueSubTypeDescription: ''
    },
    onSubmit: values => {

      let url:string = '';
      let bodyFormData:FormData = new FormData();

      url = process.env.REACT_APP_API_URI + '/Sharepoint/CreateQualityCustomerComplaintLogItem';
      bodyFormData.append('customerName', formik.values.customerName);
      bodyFormData.append('custPoNumber', formik.values.custPoNumber);
      bodyFormData.append('contactName', formik.values.contactName);
      bodyFormData.append('phone', formik.values.phone);
      bodyFormData.append('email', formik.values.email);
      bodyFormData.append('contactPosition', formik.values.contactPosition);
      bodyFormData.append('addressLine1', formik.values.addressLine1);
      bodyFormData.append('addressLine2', formik.values.addressLine2);
      bodyFormData.append('city', formik.values.city);
      bodyFormData.append('state', formik.values.state);
      bodyFormData.append('postalCode', formik.values.postalCode);
      bodyFormData.append('countryCode', formik.values.countryCode);
      bodyFormData.append('complaintDescription', formik.values.description);
      bodyFormData.append('categoryCode', formik.values.categoryCode);
      bodyFormData.append('requestTypeCode', formik.values.requestTypeCode);
      bodyFormData.append('issueType', issueType);
      bodyFormData.append('issue', issue);
      bodyFormData.append('description', formik.values.description);
      bodyFormData.append('otherIssueSubTypeDescription', formik.values.otherIssueSubTypeDescription);
      bodyFormData.append('fileUpload', selectedFile);
      bodyFormData.append('itemNumber', selectedLineItems);

      setStatus('');
      setLoading(true);
      axios({
        method: 'POST',
        url: url,
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" }
      })
        .then(res => {
          setLoading(false);
          setStatus('Thank you for your feedback!');
          // setTimeout(() => {
          //   setStatus('');
          // }, parseInt(process.env.REACT_APP_TIME_DELAY || '0'));
        })
        .catch(error => {
          setLoading(false);
          console.log(error);
          //TODO: always a happy message, for now, may need to do logging and emailing eventually
          setStatus('Thank you for your feedback!');
          // setTimeout(() => {
          //   setStatus('');
          // }, parseInt(process.env.REACT_APP_TIME_DELAY || '0'));
        })
    },
    validationSchema: Yup.object({
      contactName: Yup
        .string()
        .required("Required"),
      contactPosition: Yup
        .string()
        .required("Required"),
      email: Yup
        .string()
        .email()
        .required("Required"),
      countryCode: Yup
        .string()
        .required("Required"),
      requestTypeCode: Yup
        .string()
        .required("Required"),
      issueJSON: Yup
        .string()
        .required("Required"),
      description: Yup
        .string()
        .required("Required"),
      otherIssueSubTypeDescription: Yup
        .string()
        .when("issueJSON", {
            is: (issueJSON: any) => (issueJSON) && JSON.parse(issueJSON).issueSubTypeCode === 'OTHER',
            then: Yup.string().required("Required")
          }
        )
    })
  });

  useEffect(() => {

    //Get Countries
    setLoading(true);
    axios.get(process.env.REACT_APP_API_URI + '/Lookup/GetCountries')
      .then(res => {
        setCountries(res.data);
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        setCountries([]);
        setLoading(false);
      });

    //Get Request Types
    setLoading(true);
    axios.get(process.env.REACT_APP_API_URI + '/Lookup/GetRequestTypes')
      .then(res => {
        setRequestTypes(res.data);
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        setRequestTypes([]);
        setLoading(false);
      });

  }, []);

  useEffect(() => {
    //Get Issue Types
    setLoading(true);
    axios.get(process.env.REACT_APP_API_URI + '/Lookup/GetCombinedIssueTypes/' + formik.values.requestTypeCode)
      .then(res => {
        setCombinedIssueTypes(res.data);
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        setCombinedIssueTypes([]);
        setLoading(false);
      });

    formik.values.issueJSON = '';
    formik.values.otherIssueSubTypeDescription = '';

    if (formik.values.requestTypeCode === 'COMPLAINT') {
      setFormLabels({ typeLabel: 'Choose Issue Type' })
    }
    else if (formik.values.requestTypeCode === 'INFORMATION') {
      setFormLabels({ typeLabel: 'Choose Information Request' })
    }
    else {
      setFormLabels({ typeLabel: '' })
    }
  }, [formik.values.requestTypeCode]);

  useEffect(() => {
    const startSlice = pageNumber * ITEMS_PER_PAGE;
    const endSlice = startSlice + ITEMS_PER_PAGE;
    setCurrentItems(lineItems.slice(startSlice, endSlice))
  }, [pageNumber]);

  useEffect(() => {
    if (formik.values.issueJSON) {
      let obj = JSON.parse(formik.values.issueJSON);
      setIssueType(obj.issueTypeCode);
      setIssue(obj.issueSubTypeCode);
    } else {
      setIssueType(null);
      setIssue(null);
    }
  }, [formik.values.issueJSON]);

  const onLineItemChecked = (e: ChangeEvent<HTMLInputElement>, obj: any) => {
    const tempLineItems = lineItems.map((item: any) => {
      if (item.rowNumber === obj.rowNumber) {
        item.selected = e.target.checked;
      }
      return item;
    });

    // clear and repopulate selected items
    let tempData: any = [];
    tempLineItems.map((item: any) => {
      if (item.selected === true) {
        if(tempData.indexOf(item.itemNumber) === -1) {
          tempData.push(item.itemNumber);
        }
      }
    });
    setSelectedLineItems(tempData.sort().join());
    setLineItems(tempLineItems);
  }

  const handlePageClick = (event: any) => {
    setPageNumber(event.selected);
  };

  const setImageUpload = (e: any) => {
    let file = e.currentTarget.files[0];
    if (file) {
      if (file.size > REACT_APP_FILESIZE_LIMIT)
      {
        alert ('File is too large.');
        clearFileUpload();
        return;
      }
      if ((file.type.startsWith("image/")) || (file.type === "application/pdf")) {
        setSelectedFile(file);
        const objectUrl = URL.createObjectURL(file);
        setPreview(objectUrl);
      }
      else {
        alert ('Please upload an image or PDF only.');
        clearFileUpload();
        return;
      }
    }
    else {
      clearFileUpload();
    }
  }

  const clearSearchForm = () => {
    formikSearch.resetForm();
    formik.setValues( {
      customerName: '',
      custPoNumber: '',
      contactName: '',
      phone: '',
      email: '',
      contactPosition: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      postalCode: '',
      countryCode: 'US',
      categoryCode: '',
      requestTypeCode: '',
      issueJSON: '',
      description: '',
      otherIssueSubTypeDescription: ''
    });
    clearFileUpload();
    setLineItems([]);
    setCurrentItems([]);
    setSelectedLineItems('');
    setPageCount(0);
    setStatus('');
  }

  const clearFileUpload = () => {
    // @ts-ignore
    aRef.current.value = null;
    setSelectedFile(null);
    setPreview(null);
  }

  return (
    <>

      <Container>
        <h1 style={{
          margin: '15px 0',
          color: '#555555',
          font: '42px Oswald, "sans-serif"',
          fontStretch: 'condensed',
          borderBottom: '1px solid #ccc'
        }}>Please provide the following information</h1>
      </Container>

      <Container className="custom-form mb-2">
        <Form className="align-items-end mb-1" onSubmit={formikSearch.handleSubmit}>

          <Row className="mb-2">
            <Col>
              Enter search criteria:
            </Col>
          </Row>

          <Row>

            <Col>
              <div className="form-floating">
                <select className={`form-select ${formikSearch.touched.orderType && formikSearch.errors.orderType ? 'input-error' : ''}`}
                  id="orderType"
                  name="orderType"
                  value={formikSearch.values.orderType}
                  onChange={formikSearch.handleChange}
                  onBlur={formikSearch.handleBlur}
                  autoComplete="off" >
                    <option value="">Select Type</option>
                    <option value="1">Customer PO #</option>
                    <option value="2">Rubber Fab's Sales Order #</option>
                </select>
                <label className="form-label" htmlFor="orderType">Type</label>
              </div>
            </Col>

            <Col>
              <div className="form-floating">
                <input
                  className={`form-control ${formikSearch.touched.orderNumber && formikSearch.errors.orderNumber ? 'input-error' : ''}`}
                  id="orderNumber"
                  name="orderNumber"
                  value={formikSearch.values.orderNumber}
                  onChange={formikSearch.handleChange}
                  onBlur={formikSearch.handleBlur}
                  autoComplete="off" />
                <label className="form-label" htmlFor="orderNumber">PO/Order #</label>
              </div>
            </Col>

            <Col className="d-flex aligns-items-center justify-content-center">
                <span>
                  <button type="submit" className="custom-button-lg me-2" disabled={!(formikSearch.isValid && formikSearch.dirty)}>Search</button>
                  <button type="button" className="custom-button-lg" onClick={() => clearSearchForm()}>Clear Search</button>
                </span>
            </Col>

          </Row>

        </Form>

        <Spinner loading={loading} />

      </Container>

      <Container className="custom-form">
        <form className="mt-3" onSubmit={formik.handleSubmit}>

          <Row className="mb-1">

            <Col>
              <div className="form-floating">
                <input
                  className={`form-control ${formik.touched.contactName && formik.errors.contactName ? 'input-error' : ''}`}
                  id="contactName"
                  name="contactName"
                  value={formik.values.contactName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  autoComplete="off" />
                <label className="form-label form-label-required" htmlFor="contactName">Contact Name</label>
              </div>
            </Col>

            <Col>
              <div className="form-floating">
                <input
                  className={`form-control ${formik.touched.phone && formik.errors.phone ? 'input-error' : ''}`}
                  id="phone"
                  name="phone"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  disabled
                  autoComplete="off" />
                <label className="form-label" htmlFor="phone">Phone</label>
              </div>
            </Col>

            <Col>
              <div className="form-floating">
                <input
                  className={`form-control ${formik.touched.contactPosition && formik.errors.contactPosition ? 'input-error' : ''}`}
                  id="contactPosition"
                  name="contactPosition"
                  value={formik.values.contactPosition}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  autoComplete="off" />
                <label className="form-label form-label-required" htmlFor="contactPosition">Position</label>
              </div>
            </Col>

          </Row>

          <Row className="mb-1">

            <Col>
              <div className="form-floating">
                <input
                  className={`form-control ${formik.touched.customerName && formik.errors.customerName ? 'input-error' : ''}`}
                  id="customerName"
                  name="customerName"
                  value={formik.values.customerName}
                  onChange={formik.handleChange}
                  disabled
                  autoComplete="off" />
                <label className="form-label" htmlFor="customerName">Customer Name</label>
              </div>
            </Col>

            <Col>
              <div className="form-floating">
                <input
                  className={`form-control ${formik.touched.addressLine1 && formik.errors.addressLine1 ? 'input-error' : ''}`}
                  id="addressLine1"
                  name="addressLine1"
                  value={formik.values.addressLine1}
                  onChange={formik.handleChange}
                  disabled
                  autoComplete="off" />
                <label className="form-label" htmlFor="addressLine1">Address Line 1</label>
              </div>
            </Col>

            <Col>
              <div className="form-floating">
                <input
                  className="form-control"
                  id="addressLine2"
                  name="addressLine2"
                  value={formik.values.addressLine2}
                  onChange={formik.handleChange}
                  disabled
                  autoComplete="off" />
                <label className="form-label" htmlFor="addressLine2">Address Line 2</label>
              </div>
            </Col>

          </Row>

          <Row className="mb-1">

            <Col>
              <div className="form-floating">
                <input
                  className={`form-control ${formik.touched.custPoNumber && formik.errors.custPoNumber ? 'input-error' : ''}`}
                  id="custPoNumber"
                  name="custPoNumber"
                  value={formik.values.custPoNumber}
                  onChange={formik.handleChange}
                  disabled
                  autoComplete="off" />
                <label className="form-label" htmlFor="custPoNumber">Customer PO #</label>
              </div>
            </Col>

            <Col>
              <div className="form-floating">
                <input
                  className={`form-control ${formik.touched.city && formik.errors.city ? 'input-error' : ''}`}
                  id="city"
                  name="city"
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  disabled
                  autoComplete="off" />
                <label className="form-label" htmlFor="city">City</label>
              </div>
            </Col>

            <Col>
              <div className="form-floating">
                <input
                  className={`form-control ${formik.touched.state && formik.errors.state ? 'input-error' : ''}`}
                  id="state"
                  name="state"
                  value={formik.values.state}
                  onChange={formik.handleChange}
                  disabled
                  autoComplete="off" />
                <label className="form-label" htmlFor="state">State</label>
              </div>
            </Col>

            <Col>
              <div className="form-floating">
                <input
                  className={`form-control ${formik.touched.postalCode && formik.errors.postalCode ? 'input-error' : ''}`}
                  id="postalCode"
                  name="postalCode"
                  value={formik.values.postalCode}
                  onChange={formik.handleChange}
                  disabled
                  autoComplete="off" />
                <label className="form-label" htmlFor="postalCode">Postal Code</label>
              </div>
            </Col>

          </Row>

          <Row className="mb-1">

            <Col>
              <div className="form-floating">
                <input
                  className={`form-control ${formik.touched.email && formik.errors.email ? 'input-error' : ''}`}
                  id="email"
                  name="email"
                  type="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  autoComplete="off" />
                <label className="form-label form-label-required" htmlFor="email">Email</label>
              </div>
            </Col>

            <Col>
              <div className="form-floating">
                <select className={`form-select ${formik.touched.countryCode && formik.errors.countryCode ? 'input-error' : ''}`}
                  id="countryCode"
                  name="countryCode"
                  value={formik.values.countryCode}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}>
                    <option key="" value="">Choose Country</option>
                    {countries.map((country: any) => (
                      <option key={country.countryCode} value={country.countryCode}>{country.countryName}</option>
                    ))}
                </select>
                <label className="form-label form-label-required" htmlFor="countryCode">Country</label>
              </div>
            </Col>

          </Row>

          <Row className="mb-1">

            <Col>
              <div className="form-floating">
                <input
                  className={`form-control ${formik.touched.categoryCode && formik.errors.categoryCode ? 'input-error' : ''}`}
                  id="categoryCode"
                  name="categoryCode"
                  value={formik.values.categoryCode}
                  onChange={formik.handleChange}
                  disabled
                  autoComplete="off" />
                <label className="form-label" htmlFor="categoryCode">Category</label>
              </div>
            </Col>

            <Col>
              <div className="form-floating">
                <select className={`form-select ${formik.touched.requestTypeCode && formik.errors.requestTypeCode ? 'input-error' : ''}`}
                        id="requestTypeCode"
                        name="requestTypeCode"
                        value={formik.values.requestTypeCode}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}>
                  <option key="" value="">Choose Request Type</option>
                  {requestTypes.map((requestType: any) => (
                    <option key={requestType.requestTypeCode} value={requestType.requestTypeCode}>{requestType.requestTypeName}</option>
                  ))}
                </select>
                <label className="form-label form-label-required" htmlFor="requestTypeCode">Request Type</label>
              </div>
            </Col>

            <Col>

              <Row className="mb-1">

                <Col>
                  <div className="form-floating">
                    <select className={`form-select ${formik.touched.issueJSON && formik.errors.issueJSON ? 'input-error' : ''}`}
                            id="issueJSON"
                            name="issueJSON"
                            value={formik.values.issueJSON}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            disabled={formik.values.requestTypeCode === ''}>
                      <option key="" value="">{formLabels.typeLabel}</option>
                      {combinedIssueTypes.map((item: any, index: any) => (
                        <optgroup key={index} label={item.issueTypeName}>
                          {item.issueSubTypes.map((item: any, index: any) => (
                            <option key={index} value={JSON.stringify(item)}>{item.issueSubTypeName}</option>
                          ))}
                        </optgroup>
                      ))}
                    </select>
                    <label className="form-label form-label-required" htmlFor="issueSubTypeCode">{formLabels.typeLabel}</label>
                  </div>
                </Col>

              </Row>

              {issue === 'OTHER' &&
                  <Row className="mt-1 mb-1">

                      <Col>
                          <div className="form-floating">
                              <input
                                  className={`form-control ${formik.touched.otherIssueSubTypeDescription && formik.errors.otherIssueSubTypeDescription ? 'input-error' : ''}`}
                                  id="otherIssueSubTypeDescription"
                                  name="otherIssueSubTypeDescription"
                                  value={formik.values.otherIssueSubTypeDescription}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  autoComplete="off"/>
                              <label className="form-label form-label-required" htmlFor="otherIssueSubTypeDescription"><i>Please Explain</i></label>
                          </div>
                      </Col>

                  </Row>
              }

            </Col>

          </Row>

          <Row className="mb-1">

            <Col xs={6}>
              <div className="form-floating">
                <textarea
                  className={`form-control ${formik.touched.description && formik.errors.description ? 'input-error' : ''}`}
                  id="description"
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  rows={3}>
                </textarea>
                <label className="form-label form-label-required" htmlFor="description">Issue Description</label>
              </div>
            </Col>

            <Col>
              <div className="form-group">
                <label htmlFor="file">File upload</label>
                <input
                  className="form-control"
                  ref={aRef}
                  id="image"
                  name="image"
                  type="file"
                  accept="image/*,application/pdf"
                  onChange={(e) => setImageUpload(e)} />
              </div>
              <p><i>** Please upload ONLY images or PDF files, and no larger than 2MB **</i></p>
            </Col>

            {selectedFile && selectedFile.type.startsWith("image/") &&
              <Col>
                <img className="img-thumbnail mt-2" alt="" src={preview} height={200} width={200}/>
              </Col>
            }

          </Row>

          <Row className="mt-3 mb-3">

            {/*<hr />*/}

            {currentItems &&
              <OrderLineItems
                lineItems={currentItems}
                pageCount={pageCount}
                itemsPerPage={ITEMS_PER_PAGE}
                selectedLineItems={selectedLineItems}
                handlePageClick={handlePageClick}
                pageNumber={pageNumber}
                onLineItemChecked={(e: ChangeEvent<HTMLInputElement>, item: any) => onLineItemChecked(e, item)}/>
            }

            <Col className="text-center">
              <button className="custom-button-lg me-2" type="submit" disabled={!(formik.isValid && formik.dirty && (selectedLineItems !== ''))}>Submit</button>
            </Col>

          </Row>

          <ResultStatus message={status}/>

        </form>
      </Container>

    </>
  );
}

export default CustomerInquiries;
