import React from 'react';

export interface ResultStatusProps {
  message: string;
}

const ResultStatus: React.FC<ResultStatusProps> = ({message}) => {

  let show: boolean;
  let alert: string = '';
  let msg: string = '';

  if (message && message.length > 0) {
    show = true;
    if (message.length >= 4 && (message.slice(0,4) === 'ERR#')) {
      alert = 'danger';
      msg = message.slice(4);
    } else {
      alert = 'success';
      msg = message;
    }
  } else {
    show = false;
  }

  return (
    <>
      {show &&
          <div className={`alert alert-${alert}`} role="alert">
            {msg}
          </div>
      }
    </>
  );

}

export default ResultStatus;